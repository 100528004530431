@font-face {
  font-family: "AmpleSoft Pro";
  src: url("../fonts/subset-AmpleSoftPro-Thin.eot");
  src: url("../fonts/subset-AmpleSoftPro-Thin.eot?#iefix") format("embedded-opentype"),
  url("../fonts/subset-AmpleSoftPro-Thin.woff2") format("woff2"),
  url("../fonts/subset-AmpleSoftPro-Thin.woff") format("woff"),
  url("../fonts/subset-AmpleSoftPro-Thin.ttf") format("truetype"),
  url("../fonts/subset-AmpleSoftPro-Thin.svg#AmpleSoftPro-Thin") format("svg");
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: "AmpleSoft Pro";
  src: url("../fonts/subset-AmpleSoftPro-Bold.eot");
  src: url("../fonts/subset-AmpleSoftPro-Bold.eot?#iefix") format("embedded-opentype"),
  url("../fonts/subset-AmpleSoftPro-Bold.woff2") format("woff2"),
  url("../fonts/subset-AmpleSoftPro-Bold.woff") format("woff"),
  url("../fonts/subset-AmpleSoftPro-Bold.ttf") format("truetype"),
  url("../fonts/subset-AmpleSoftPro-Bold.svg#AmpleSoftPro-Bold") format("svg");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "AmpleSoft Pro";
  src: url("../fonts/subset-AmpleSoftPro-ExtraLight.eot");
  src: url("../fonts/subset-AmpleSoftPro-ExtraLight.eot?#iefix") format("embedded-opentype"),
  url("../fonts/subset-AmpleSoftPro-ExtraLight.woff2") format("woff2"),
  url("../fonts/subset-AmpleSoftPro-ExtraLight.woff") format("woff"),
  url("../fonts/subset-AmpleSoftPro-ExtraLight.ttf") format("truetype"),
  url("../fonts/subset-AmpleSoftPro-ExtraLight.svg#AmpleSoftPro-ExtraLight") format("svg");
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: "AmpleSoft Pro";
  src: url("../fonts/subset-AmpleSoftPro-Regular.eot");
  src: url("../fonts/subset-AmpleSoftPro-Regular.eot?#iefix") format("embedded-opentype"),
  url("../fonts/subset-AmpleSoftPro-Regular.woff2") format("woff2"),
  url("../fonts/subset-AmpleSoftPro-Regular.woff") format("woff"),
  url("../fonts/subset-AmpleSoftPro-Regular.ttf") format("truetype"),
  url("../fonts/subset-AmpleSoftPro-Regular.svg#AmpleSoftPro-Regular") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "AmpleSoft Pro";
  src: url("../fonts/subset-AmpleSoftPro-Medium.eot");
  src: url("../fonts/subset-AmpleSoftPro-Medium.eot?#iefix") format("embedded-opentype"),
  url("../fonts/subset-AmpleSoftPro-Medium.woff2") format("woff2"),
  url("../fonts/subset-AmpleSoftPro-Medium.woff") format("woff"),
  url("../fonts/subset-AmpleSoftPro-Medium.ttf") format("truetype"),
  url("../fonts/subset-AmpleSoftPro-Medium.svg#AmpleSoftPro-Medium") format("svg");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "AmpleSoft Pro";
  src: url("../fonts/subset-AmpleSoftPro-Light.eot");
  src: url("../fonts/subset-AmpleSoftPro-Light.eot?#iefix") format("embedded-opentype"),
  url("../fonts/subset-AmpleSoftPro-Light.woff2") format("woff2"),
  url("../fonts/subset-AmpleSoftPro-Light.woff") format("woff"),
  url("../fonts/subset-AmpleSoftPro-Light.ttf") format("truetype"),
  url("../fonts/subset-AmpleSoftPro-Light.svg#AmpleSoftPro-Light") format("svg");
  font-weight: 300;
  font-style: normal;
}
